<script>
import {isLocalhost} from '@/libraries/Utilities'

export default {
    name: "MixinsHandleShopData",
    data: function(){
        return {
            isDesktop: false,
        }
    },
    computed: {
        isLocalhost() {
            return isLocalhost();
        },
    },
    methods: {
        handleShopData(){
            if(this.shopData && this.shopData.favicon){
                const favicon = (document.querySelectorAll("link[rel*='icon']"));
                let that = this;
                favicon.forEach( function(fav){
                    fav.rel = 'shortcut icon';
                    fav.href = that.shopData.favicon;
                    document.getElementsByTagName('head')[0].appendChild(fav);
                });
            }
        },
        changeThemeColor(){
            let color = null;
            if(this.isRealMall){
                color = '#0180ff';
            }else if(this.shopData?.setting?.color){
                color = this.shopData?.setting.color;
            }
            if(color){
                this.$vuetify.theme.themes.light.primary = color;
                this.$vuetify.theme.themes.dark.primary = color;
            }
            var themeColorMeta = document.querySelector('meta[name="theme-color"]');
            if (themeColorMeta) {
                themeColorMeta.content = color;
            } else {
                var metaTag = document.createElement('meta');
                metaTag.name = 'theme-color';
                metaTag.content = color;
                var head = document.head || document.getElementsByTagName('head')[0];
                head.appendChild(metaTag);
            }
        },
    },
    mounted: function(){

    }
}
</script>