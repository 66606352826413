import i18n from "@/i18n.js";

const thai_date_time = (format, datetime_string) => {
    if (/^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2})?$/.test(datetime_string)) {
        try {
            const datetime_arr = datetime_string.split(/[- :]/);
            if (datetime_arr.length < 3) {
                return datetime_string;
            }
            const date = (datetime_arr.length >= 6) ? new Date(datetime_arr[0], datetime_arr[1] - 1, datetime_arr[2], datetime_arr[3], datetime_arr[4], datetime_arr[5]) : new Date(datetime_arr[0], datetime_arr[1] - 1, datetime_arr[2]);
            //const date = (new Date(datetime_string));
            const months = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
            // const en_months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

            let result = '';

            format.split('').forEach((c) => {
                switch (c) {
                    case 'j':
                        result += date.getDate();
                        break;
                    case 'm':
                        result += months[date.getMonth()];
                        break;
                    case 'y':
                        result += (date.getFullYear() + 543) % 100;
                        break;
                    case 'Y':
                        result += date.getFullYear() + 543;
                        break;
                    case 'H':
                        result += ('00' + date.getHours()).slice(-2);
                        break;
                    case 'i':
                        result += ('00' + date.getMinutes()).slice(-2);
                        break;
                    default:
                        result += c;
                        break;
                }
            });
            return result;
        } catch (e) {
            // eslint-disable-next-line
            console.error(e);
        }
    }
    return datetime_string;
}

export const ThaiDateTime_format1 = (datetime_string) => thai_date_time('j m y เวลา H:i น.', datetime_string);

export const ThaiDateTime_format2 = (datetime_string) => thai_date_time('j m y · H:i น.', datetime_string);

export const ThaiDate_mini = (datetime_string) => thai_date_time('j m y', datetime_string);

export const clock = {
    datetime_diff_data: function (d1, d2, focus_key) {
        let a = d1;
        let b = d2;
        let is_less = false;
        if (a < b) {
            a = d2;
            b = d1;
            is_less = true;
        }
        if ((a.length > 10) && (b.length === 10)) {
            b = b + ' 00:00:00';
        } else if ((b.length > 10) && (a.length === 10)) {
            a = a + ' 00:00:00';
        }
        if (focus_key) {
            let divide_val = 1000;
            let a_year = 0;
            let b_year = 0;
            let a_month = 0;
            let b_month = 0;
            let a_day = 0;
            let b_day = 0;
            switch (focus_key) {
                case 'year':
                    a_year = parseInt(a.substr(0, 4));
                    b_year = parseInt(b.substr(0, 4));
                    a_month = parseInt(a.substr(5, 2));
                    b_month = parseInt(b.substr(5, 2));
                    if (a_month < b_month) {
                        a_year -= 1;
                    }
                    return {
                        is_less: is_less,
                        year: a_year - b_year
                    }
                case 'month':
                    a_year = parseInt(a.substr(0, 4));
                    b_year = parseInt(b.substr(0, 4));
                    a_month = parseInt(a.substr(5, 2));
                    b_month = parseInt(b.substr(5, 2));
                    a_day = parseInt(a.substr(8, 2));
                    b_day = parseInt(b.substr(8, 2));
                    a_month += (a_year - b_year) * 12;
                    if (a_day < b_day) {
                        a_month -= 1;
                    }
                    return {
                        is_less: is_less,
                        month: a_month - b_month
                    }
                case 'day':
                    divide_val = 86400000;
                    break;
                case 'hour':
                    divide_val = 3600000;
                    break;
                case 'minute':
                    divide_val = 60000;
                    break;
                case 'second':
                    divide_val = 1000;
                    break;
                default:
                    return false;
            }
            let a_time = new Date(a).getTime();
            let b_time = new Date(b).getTime();
            let diff_time = a_time - b_time;
            let ret = {
                is_less: is_less
            };
            ret[focus_key] = Math.floor(diff_time / divide_val);
            return ret;
        }
        const max_day_in_month = [31, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        const a_year = parseInt(a.substr(0, 4));
        const b_year = parseInt(b.substr(0, 4));
        const a_month = parseInt(a.substr(5, 2));
        const b_month = parseInt(b.substr(5, 2));
        const a_day = parseInt(a.substr(8, 2));
        const b_day = parseInt(b.substr(8, 2));
        let dd = {
            is_less: is_less,
            year: a_year - b_year,
            month: a_month - b_month,
            day: a_day - b_day,
            hour: 0,
            minute: 0,
            second: 0
        };
        const a_length = a.length;
        if (a_length > 10) {
            const a_hour = parseInt(a.substr(11, 2));
            const b_hour = parseInt(b.substr(11, 2));
            dd.hour = a_hour - b_hour;
            if (a_length > 13) {
                const a_minute = parseInt(a.substr(14, 2));
                const b_minute = parseInt(b.substr(14, 2));
                dd.minute = a_minute - b_minute;
                if (a_length > 16) {
                    const a_second = parseInt(a.substr(17, 2));
                    const b_second = parseInt(b.substr(17, 2));
                    dd.second = a_second - b_second;
                }
            }
        }
        let recv_minus_func = function (dd) {
            if (dd.month < 0) {
                dd.year -= 1;
                dd.month += 12;
                return true;
            }
            if (dd.day < 0) {
                dd.month -= 1;
                var max_day = max_day_in_month[a_month - 1];
                if ((a_month === 3) && (a_year % 4 === 0)) {
                    max_day += 1;
                }
                dd.day += max_day;
                return true;
            }
            if (dd.hour < 0) {
                dd.day -= 1;
                dd.hour += 24;
                return true;
            }
            if (dd.minute < 0) {
                dd.hour -= 1;
                dd.minute += 60;
                return true;
            }
            if (dd.second < 0) {
                dd.minute -= 1;
                dd.second += 60;
                return true;
            }
            return false;
        }
        // noinspection StatementWithEmptyBodyJS
        while (recv_minus_func(dd)) ;
        return dd;
    },
    date_diff_data: function (d1, d2) {
        return this.datetime_diff_data(d1, d2);
    },
    time_diff_data: function (t1, t2) {
        return this.datetime_diff_data('0000-00-00 ' + t1, '0000-00-00 ' + t2);
    },

    now_date: function () {
        let pad = function (str, len, chr, leftJustify) {
            if (typeof str == 'number') {
                str = '' + str;
            }
            if (!chr) {
                chr = ' ';
            }
            let padding = (str.length >= len) ? '' : new Array(1 + len - str.length >>> 0).join(chr);
            return leftJustify ? str + padding : padding + str;
        };

        let date = new Date();

        return date.getFullYear() + '-' + pad(date.getMonth() + 1, 2, '0') + '-' + pad(date.getDate(), 2, '0');
    },
    now_datetime: function () {
        let pad = function (str, len, chr, leftJustify) {
            if (typeof str == 'number') {
                str = '' + str;
            }
            if (!chr) {
                chr = ' ';
            }
            let padding = (str.length >= len) ? '' : new Array(1 + len - str.length >>> 0).join(chr);
            return leftJustify ? str + padding : padding + str;
        };

        let date = new Date();

        return date.getFullYear() + '-' + pad(date.getMonth() + 1, 2, '0') + '-' + pad(date.getDate(), 2, '0') + ' ' + pad(date.getHours(), 2, '0') + ':' + pad(date.getMinutes(), 2, '0') + ':' + pad(date.getSeconds(), 2, '0');
    },
    diff_text: function (d1, d2, show_key) {
        show_key = show_key || false;
        let diff_data = this.datetime_diff_data(d1, d2);
        let data_keys = ['year', 'month', 'day', 'hour', 'minute', 'second'];
        // let data_keys = ['year', 'month', 'day', 'hour', 'minute'];
        let data_texts = {
            year: i18n.t('ปี'),
            month: i18n.t('เดือน'),
            day: i18n.t('วัน'),
            hour: i18n.t('ชั่วโมง'),
            minute: i18n.t('นาที'),
            second: i18n.t('วินาที'),
        };
        let txts = [];
        if (typeof show_key === 'string') {
            txts.push(diff_data[show_key] + ' ' + data_texts[show_key]);
        } else if (typeof show_key === 'number') {
            let first_idx = null;
            data_keys.forEach(function (data_key, idx) {
                if (diff_data[data_key] > 0) {
                    if (first_idx == null) {
                        first_idx = idx;
                    }
                }
                if ((first_idx !== null) && (idx < (first_idx + show_key))) {
                    txts.push(diff_data[data_key] + ' ' + data_texts[data_key]);
                }
            });
        } else {
            data_keys.forEach(function (data_key) {
                if (diff_data[data_key] > 0) {
                    if(!(data_key == 'second' && txts.length > 0)){
                        txts.push(diff_data[data_key] + ' ' + data_texts[data_key]);
                    }
                }
            });
        }
        if (txts.length === 0) {
            return '';
        }
        return (diff_data.is_less ? '- ' : '') + txts.join(' ');
    },
    diff_now_text: function (d2) {
        if (typeof d2 != 'string') {
            return '';
        }
        let d1 = this.now_datetime();
        if (d2.length === 10) {
            d1 = d1.substr(0, 10);
        }
        if (d1 > d2) {
            return this.diff_text(d1, d2, 1) + i18n.t('ที่ผ่านมา');
        } else if (d1 < d2) {
            return this.diff_text(d2, d1, 1) + i18n.t('ข้างหน้า');
        }
        if (d2.length === 10) {
            return i18n.t('วันนี้');
        }
        return i18n.t('ขณะนี้');
    },
    short_month_arr: ["", "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
    month_arr: ["", "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"],

    monthNames: ["", "January","February","March","April","May","June",
        "July","August","September","October","November","December"],
    monthNamesShort: ["", "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],

    show_date: function (date, long_format) {
        let year = parseInt(date.substr(0, 4));
        let month = parseInt(date.substr(5, 2));
        let day = parseInt(date.substr(8, 2));
        if(i18n.locale == 'th'){
            if (long_format) {
                return day + ' ' + this.month_arr[month] + ' ' + (year + 543);
            }
            return day + ' ' + this.short_month_arr[month] + ' ' + ((year + 543) % 100);
        }else{
            if (long_format) {
                return day + ' ' + this.monthNames[month] + ' ' + (year);
            }
            return day + ' ' + this.monthNamesShort[month] + ' ' + ((year) % 100);
        }
    },
    show_time: function (time) {
        let hour = (time.substr(0, 2));
        let minute = (time.substr(3, 2));
        //let second = (time.substr(6,2));
        return hour + ':' + minute + ' น.';
    },
    show_datetime: function (datetime) {
        if (typeof datetime != 'string') {
            return '';
        }
        let date = datetime.substr(0, 10);
        let time = datetime.substr(11, 6);
        return this.show_date(date) + ' · ' + this.show_time(time);
    }
}