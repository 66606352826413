<template>
    <v-speed-dial v-if="isShowContactButton"
                  v-model="contactButton"
                  fixed
                  bottom
                  right
                  style="z-index: 999;"
                  transition="slide-y-reverse-transition">
        <template v-slot:activator>
            <v-btn v-model="contactButton"
                   ref="contactButton"
                   dark
                   fab
                   color="light-green accent-4">
                <v-icon v-if="contactButton">
                    mdi-close
                </v-icon>
                <v-icon v-else>
                    mdi-message-outline
                </v-icon>
            </v-btn>
        </template>
        <v-tooltip left
                   transition="fade-transition"
                   color="#999"
                   :value="showTooltipContactButton"
                   v-for="(value , key) in contactList"
                   :key="key">
            <template v-slot:activator="{ on }">
                <v-btn :href="contactMeta[key].url + value"
                       target="_blank"
                       @click="actionTracker_contact"
                       fab
                       dark
                       small
                       :color="contactMeta[key].color">
                    <v-icon v-if="contactMeta[key].icon">{{ contactMeta[key].icon }}</v-icon>
                    <img v-if="key === 'line'"
                         style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);"
                         :src="require('@/assets/social-svg/line.svg')"
                         alt="Line"
                         width="20"> <img v-if="key === 'tiktok'"
                                          style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);"
                                          :src="require('@/assets/social-svg/tiktok.svg')"
                                          alt="Tiktok"
                                          width="20">
                </v-btn>
            </template>
            {{ value }}
        </v-tooltip>
        <v-tooltip v-if="false"
                   left
                   color="#999"
                   transition="slide-x-reverse-transition"
                   :value="showTooltipContactButton">
            <template v-slot:activator="{ on }">
                <v-btn @click="scrollTo('#newMessageContainer');"
                       fab
                       dark
                       small
                       color="primary">
                    <v-icon>mdi-message</v-icon>
                </v-btn>
            </template>
            {{ $t('ฝากข้อความทันที') }}
        </v-tooltip>
    </v-speed-dial>
</template>
<script>
import {mapActions, mapState, mapGetters} from "vuex";
import MixinsContactMeta from "@/mixins/MixinsContactMeta";

export default {
    name: "ContactButton",
    props: ['value'],
    mixins: [
        MixinsContactMeta
    ],
    data(){
        return {};
    },
    computed: {
        ...mapGetters({}),
        ...mapState({
            shopData: state => state.order.shopData ? state.order.shopData : (state.myOrders.shopData),
            status: state => state.order.orderData?.order_status,
        }),
        isShowContactButton(){
            let show = false;
            if(!this.shopData) return false;
            if(this.status && !(this.status === 'wait_order' || this.status === 'wait_payment')){
                show = true;
            }
            if(!this.shopData.is_close || this.shopData.show_contact_when_close || this.shopData.show_primary_contact_when_close){
                show = true;
            }else{
                show = false;
            }
            return show;
        }
    },
    methods: {
        ...mapActions({
            actionTracker_contact: 'order/tracker_contact',
        })
    },
}
</script>
<style scoped></style>