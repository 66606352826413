<script>
import { mapActions, mapState } from "vuex";

export default {
    computed: {
      ...mapState({
        existingOrderOwner: state => state.order.existingOrderOwner,
      }),
    },
    methods: {
        ...mapActions({
            tracker_login: 'order/tracker_login',
        }),
        __social_login_exec(provider, claim) {
            this.tracker_login({method: provider});
            let _continue_url = window.location.href;
            if(claim){
                _continue_url += '&claim=1';
            }
            const continue_url = encodeURIComponent(_continue_url);
            let target_url = `https://lnw.me/account/3/login/quick?provider=${provider}&continue_url=${continue_url}`;
            if (this.existingOrderOwner && this.existingOrderOwner.encoded_user_id) {
              target_url += '&expected_encoded_user_id=' + encodeURIComponent(this.existingOrderOwner.encoded_user_id);
              // ต้องเอา lnw_access_token ออก เพื่อให้ขากลับมา ยิง check_session ใหม่อีกครั้ง
              sessionStorage.removeItem('lnw_access_token');
            }
            window.location.href = target_url;
        },
        SocialLogin_via_Facebook(claim = false) {
            this.__social_login_exec('facebook', claim);
        },
        SocialLogin_via_Line(claim = false) {
            this.__social_login_exec('line', claim);
        },
        SocialLogin_via_Google(claim = false) {
            this.__social_login_exec('google', claim);
        },
        SocialLogin_via_Apple(claim = false) {
            this.__social_login_exec('apple', claim);
        },
    },
}
</script>