<script>
export default {
    name: 'mixinsDisabledIconFocus',
    directives: {
        disabledIconFocus: {
            bind(el) {
                el.querySelectorAll('.v-input__icon button').forEach(x => x.setAttribute('tabindex', -1));
            },
            update: function(el) {
                el.querySelectorAll('.v-input__icon button').forEach(x => x.setAttribute('tabindex', -1));
            }
        },
    },
}
</script>