<template>
    <div v-if="inDevelopment" class="file_indicator mb-3">
        {{fileName}}
        <div v-if="detail" class="detail">
            {{detail}}
        </div>
    </div>
</template>

<script>
import {inDevelopment} from '@/libraries/Utilities'
export default {
    name: "DebugIndicator",
    props: {
        fileName: {
            required: true,
            type: String,
        },
        detail: {
            type: String,
        },
    },
    computed: {
        inDevelopment() {
            return inDevelopment();
        },
    },
}
</script>

<style scoped>
.file_indicator {
    border: 1px solid red;
    font-size: 10px;
    font-weight: bold;
    padding: 2px;
    display: flex;
}
.file_indicator .detail {
    margin-left: 10px;
    color: blue;
}
</style>